import firebase from 'firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/storage';


const config = {
  apiKey: 'AIzaSyA0knzLQJG_26rtWn1aNkUWOZasxUrVvtU',
  authDomain: 'cp-gsso-dev-smartseat.firebaseapp.com',
  projectId: 'cp-gsso-dev-smartseat',
  storageBucket: 'cp-gsso-dev-smartseat.appspot.com',
  messagingSenderId: '341124046841',
  appId: '1:341124046841:web:6de7ddd590036f1e536728',
  measurementId: 'G-KW7STW5HV6',
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
  firebase.firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
  });
  firebase.firestore().enablePersistence()
  .catch((err) => {
      if (err.code === 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
          // console.log(err)
      } else if (err.code === 'unimplemented') {
          // console.log(err)
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });
 

// Subsequent queries will use persistence, if it was enabled successfully
}

export {firebase};
